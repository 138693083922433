import React from "react";


function NotFound(){
        return (
            <>
                <p className="pageContent">No page here oh no</p>
            </>
        )
    
}

export default NotFound;